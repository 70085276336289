<template>
  <ion-page class="page">
    <p>Last updated May 26, 2022</p>
    <h1>CHARACTERHUB DMCA POLICY</h1>
    <div class="margin-bottom-10">
      UsePencil, Inc. (“CharacterHub”) respects the intellectual property rights of third parties and responds to
      allegations that copyrighted material has been posted, uploaded or shared on or through the CharacterHub website
      (the “Site”) without authorization from the copyright holder in accordance with the safe harbor set forth in the
      Digital Millennium Copyright Act (“DMCA”). CharacterHub will also, in appropriate circumstances and at its
      discretion, disable and/or terminate the accounts of users who may infringe or repeatedly infringe the copyrights
      of others in accordance with the DMCA.
    </div>

    <div class="margin-bottom-10">
      <h1>{{ 'A. Notification of Alleged Copyright Infringement' }}</h1>
    </div>
    <div class="margin-bottom-10">
      If you believe that your work has been copied and made available through the Site in a way that constitutes
      copyright infringement, you may send a written document to CharacterHub’s Designated Agent (as set forth below)
      that contains the following (a “Notice”):
    </div>
    <div class="margin-bottom-10">
      <ol>
        <li>A description of the copyrighted work that you claim has been infringed.</li>
        <li>
          Identification of the URL or other specific location that contains the material that you sufficient
          information to locate the allegedly infringing material.
        </li>
        <li>
          An electronic or physical signature of the owner of the copyright or of the person authorized to act on behalf
          of the owner of the copyright.
        </li>
        <li>
          A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright
          owner, its agent, or applicable law.
        </li>
        <li>
          A statement by you that the information contained in your Notice is accurate and that you attest under the
          penalty of perjury that you are the copyright owner or that you are authorized to act on the owner's behalf.
        </li>
        <li>Your name, mailing address, telephone number, and email address.</li>
      </ol>
      <p>CharacterHub’s Designated Agent for Notice of claims of copyright infringement can be reached as follows:</p>
      <p>Copyright Department</p>
      <p>UsePencil, Inc.</p>
      <p>1259 EL CAMINO REAL #158</p>
      <p>Menlo Park, CA 94025</p>
      <p>Phone: 213 545 1659</p>
      <p>E-Mail: sydney@characterhub.com</p>
      <p>
        Please note that you may be liable for damages, including court costs and attorneys fees, if you misrepresent
        that content uploaded by a Site user is infringing your copyright.
      </p>
      <p>
        Upon receiving a proper Notice, CharacterHub will remove or disable access to the allegedly infringing material
        and notify the alleged infringer of your claim. We will also advise the alleged infringer of the DMCA Counter
        Notice Procedure described below in Section B by which the alleged infringer may respond to your claim and
        request that we restore this material.
      </p>
    </div>
    <div class="margin-bottom-10">
      <h1>{{ 'B. Counter Notice Procedure' }}</h1>
    </div>
    <div class="margin-bottom-10">
      If you believe your own copyrighted material has been removed from the Site in error, you may submit a written
      Counter Notice to our Designated Agent (as identified above) that includes the following:
    </div>
    <div class="margin-bottom-10">
      <ol>
        <li>
          Identification of the material that has been removed or disabled and the location at which the material
          appeared before it was removed or disabled.
        </li>
        <li>
          A statement that you consent to the jurisdiction of the Federal District Court in which your address is
          located, or if your address is outside the United States, the District of Massachusetts or any other judicial
          district in which CharacterHub may be found.
        </li>
        <li>
          A statement that you will accept service of process from the party that filed the Notice or the party's agent.
        </li>
        <li>Your name, address and telephone number.</li>
        <li>
          A statement under penalty of perjury that you have a good faith belief that the material in question was
          removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.
        </li>
        <li>Your physical or electronic signature.</li>
      </ol>
      <p>
        If you send our Designated Agent a valid, written Counter Notice meeting the requirements described above, we
        will restore your removed or disabled material within 10 to 14 business days from the date we receive your
        Counter Notification, unless our Designated Agent first receives notice from the party filing the original
        Notice informing us that such party has filed a court action to restrain you from engaging in infringing
        activity related to the material in question.
      </p>
      <p>
        Please note that if you misrepresent that the disabled or removed content was removed by mistake or
        misidentification, you may be liable for damages, including costs and attorney's fees.
      </p>
    </div>
    <div class="margin-bottom-10">
      <h1>{{ 'C. Repeat Infringer Policy' }}</h1>
      <p>
        CharacterHub may withdraw all rights and privileges relating to the Site from any user who is deemed to be a
        repeat infringer. This determination will be based on the number of “strikes” against the user. A “strike” is
        counted against a user each time there is either: (i) an adjudication by a court, arbitrator or other tribunal
        of competent jurisdiction that the user has engaged in copyright infringement of any kind in relation to the
        Site; or (ii) CharacterHub has actual knowledge, regardless of any such adjudication, that the user has engaged
        in any such copyright infringement.
      </p>
      <p>
        Each adjudication or instance of knowledge counts as a separate strike. If an adjudication or instance of
        knowledge pertains to multiple instances of copyright infringement, it can count as multiple strikes.
        CharacterHub has adopted a “three strikes and you’re out” policy under which a user who accumulates three
        strikes is considered a repeat infringer and may be subject to account termination.
      </p>
    </div>
  </ion-page>
</template>

<script lang="ts">
export default {};
</script>

<style lang="sass" scoped>
.red
  color: red
.bold
  font-weight: bold
.margin-bottom-10
  margin-bottom: 10px
</style>
